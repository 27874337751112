.learn-head{
    background-color: blueviolet;
    /* text-align: center; */
    padding:300px;
    /* color:white; */
    color: pink;
}

.learn{
    border-radius: 10%;
    margin:5%;
}

.learn .learn-img{
    height:160px;
    width: 200px;
    background-color: blue;
}

.product{
    background-color: aqua;
    padding:20px;
}

.disc{
    margin:10px 0;
}

.lang{
    margin:10px 10px;
    padding:10px 100px;
    font-size: 30px;
}

